import React, { useState } from "react";
import { Link } from "react-router-dom";
import Content from "../components/content";
import MenuItem from "../components/menuitem";
import BlogItem from "../components/blogitem";
import Card7 from "../components/card7";
import Contact from "../components/contact";
import Footer from "../components/footer";
import PropTypes from "prop-types";

const BlogPage = ({ className = "" }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div
      className={`absolute top-[0rem] left-[0rem] bg-background-color-primary overflow-hidden flex flex-col items-start justify-start max-w-full text-center text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
        {isMenuVisible && (
          <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
            <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
              <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Komal Group
                  </div>
                  <Link to="/aboutuspage#leadership" className="no-underline text-inherit">
                    <MenuItem pageOne="Leadership" />
                  </Link>
                  <Link to="/aboutuspage#timeline" className="no-underline text-inherit">
                    <MenuItem pageOne="Past and Future" />
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit">
                    <MenuItem pageOne="Mines" />
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit">
                    <MenuItem pageOne="SSR Power" />
                  </Link>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Offerings
                  </div>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Parks" />
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit">
                    <MenuItem pageOne="VR Realty Advisory" />
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Construction" />
                  </Link>
                  <Link to="/landaggregationpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Strategic Land Aggregations" />
                  </Link>
                </div>
              </div>
              <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
                <div className="self-stretch relative text-[0.875rem] leading-[150%] font-semibold">
                  Downloads
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
                  <Link to="/developmentmaps" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/govt-maps.png"
                      articleTitle="Development Maps"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/gr.png"
                      articleTitle="Government Resolutions"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/brochure.png"
                      articleTitle="Corporate Brochure"
                      readMore="Download"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full">
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="relative leading-[150%] font-semibold inline-block min-w-[4.5rem]">
              Discover
            </div>
            <b className="self-stretch relative text-[3.5rem] leading-[120%] text-left mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]">
              Insights on Real Estate
            </b>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[25rem] max-w-full text-left text-[1.125rem] mq750:min-w-full">
            <div className="self-stretch relative leading-[150%]">
              Stay updated with the latest trends and developments in the real
              estate market around Pune district.
            </div>
            <div className="w-[32.063rem] flex flex-col items-start justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1rem] max-w-full text-[1rem] text-color-neutral-neutral">
              <div className="self-stretch h-[3rem] flex flex-row items-start justify-start gap-[1rem] max-w-full">
                <div className="flex-1 bg-background-color-primary box-border flex flex-row items-center justify-start p-[0.75rem] min-h-[3.125rem] whitespace-nowrap max-w-[calc(100%_-_128px)] shrink-0 border-[1px] border-solid border-link-primary">
                  <div className="flex-1 relative leading-[150%] inline-block max-w-full">
                    Enter your email
                  </div>
                </div>
                <div className="h-[3.125rem] bg-link-primary box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] whitespace-nowrap shrink-0 text-background-color-primary border-[1px] border-solid border-link-primary">
                  <div className="relative leading-[150%] inline-block min-w-[3.875rem]">
                    Sign up
                  </div>
                </div>
              </div>
              <div className="self-stretch relative text-[0.75rem] leading-[150%] text-text-primary">
                By signing up, you agree to our Terms and Conditions.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <div className="w-[48rem] flex flex-col items-center justify-start gap-[1rem] max-w-full">
          <div className="relative leading-[150%] font-semibold inline-block min-w-[3.188rem]">
            Latest
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-[3.5rem]">
            <b className="self-stretch relative leading-[120%] mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]">
              Discover New Blog Posts
            </b>
            <div className="self-stretch relative text-[1.125rem] leading-[150%]">
              Stay updated with our latest blog posts.
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-center justify-start gap-[4rem] max-w-full text-left">
          <div className="self-stretch flex flex-col items-center justify-start gap-[4rem] max-w-full text-[0.875rem]">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[2rem] max-w-full">
              <Card7
                heading="The Importance of Digital Marketing"
                postSummaryPair="Learn how digital marketing can boost your business growth."
                jan2022="11 Feb 2022"
                readTimePair="7 min read"
              />
              <Card7
                heading="The Power of Social Media Marketing"
                postSummaryPair="Harness the potential of social media to grow your brand."
                jan2022="15 Feb 2022"
                readTimePair="6 min read"
                propFlex="1"
                propMinWidth="19.5rem"
              />
              <Card7
                heading="The Future of E-commerce"
                postSummaryPair="Explore the trends shaping the future of online shopping."
                jan2022="20 Feb 2022"
                readTimePair="8 min read"
                propFlex="1"
                propMinWidth="19.5rem"
              />
            </div>
            <div className="self-stretch grid flex-row items-start justify-start gap-[2rem] max-w-full grid-cols-[repeat(3,_minmax(312px,_1fr))] mq750:grid-cols-[minmax(312px,_1fr)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(312px,_541px))]">
              <Card7
                heading="The Art of Content Creation"
                postSummaryPair="Learn how to create engaging and valuable content for your audience."
                jan2022="25 Feb 2022"
                readTimePair="9 min read"
                propFlex="unset"
                propMinWidth="unset"
              />
              <Card7
                heading="The Power of Email Marketing"
                postSummaryPair="Discover how email marketing can drive conversions and customer loyalty."
                jan2022="1 Mar 2022"
                readTimePair="10 min read"
                propFlex="unset"
                propMinWidth="unset"
              />
              <Card7
                heading="The Importance of SEO"
                postSummaryPair="Learn how SEO can drive organic traffic and improve your website's visibility."
                jan2022="5 Mar 2022"
                readTimePair="7 min read"
                propFlex="unset"
                propMinWidth="unset"
              />
            </div>
          </div>
        </div>
      </div>

      <Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/line-1.svg"
        emailInput="/line-2.svg"
      />
      <Footer />
    </div>
  );
};

BlogPage.propTypes = {
  className: PropTypes.string,
};

export default BlogPage;
