import PropTypes from "prop-types";

const Card2 = ({ className = "", heading, text }) => {
  return (
    <div
      className={`flex-1 box-border flex flex-col items-start justify-start min-w-[19.563rem] max-w-full shrink-0 text-left text-[0.875rem] text-text-primary font-text-small-normal border-[1px] border-solid border-border-primary ${className}`}
    >
      <img
        className="self-stretch h-[18.75rem] relative max-w-full overflow-hidden shrink-0 object-cover"
        alt=""
        src="/placeholder-image@2x.png"
      />
      <div className="self-stretch flex flex-col items-start justify-start p-[1.5rem] gap-[1.5rem]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[1rem]">
          <div className="flex flex-row items-center justify-start gap-[1rem]">
            <div className="bg-background-color-secondary flex flex-row items-start justify-start py-[0.25rem] px-[0.5rem]">
              <div className="relative leading-[150%] font-semibold inline-block min-w-[4.125rem]">
                Category
              </div>
            </div>
            <div className="relative leading-[150%] font-semibold inline-block min-w-[4.813rem]">
              5 min read
            </div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-[1.5rem]">
            <b className="self-stretch relative leading-[140%] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
              {heading}
            </b>
            <div className="self-stretch relative text-[1rem] leading-[150%]">
              {text}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center justify-center gap-[0.5rem] text-[1rem]">
          <div className="relative leading-[150%] inline-block min-w-[5.563rem]">
            Read more
          </div>
          <img
            className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
            alt=""
            src="/icon--chevronright.svg"
          />
        </div>
      </div>
    </div>
  );
};

Card2.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default Card2;
