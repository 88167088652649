import PropTypes from "prop-types";

const Container = ({ className = "", quote, clientAvatar, avatarPicture }) => {
  return (
    <div
      className={`flex-1 overflow-hidden flex flex-col items-start justify-start gap-[2rem] min-w-[25.375rem] max-w-full text-left text-[1.25rem] text-text-primary font-text-small-normal mq750:min-w-full ${className}`}
    >
      <div className="overflow-hidden flex flex-row items-start justify-start gap-[0.25rem]">
        <img
          className="h-[1.181rem] w-[1.25rem] relative min-h-[1.188rem]"
          alt=""
          src="/testimonial-container.svg"
        />
        <img
          className="h-[1.181rem] w-[1.25rem] relative min-h-[1.188rem]"
          alt=""
          src="/testimonial-container.svg"
        />
        <img
          className="h-[1.181rem] w-[1.25rem] relative min-h-[1.188rem]"
          alt=""
          src="/testimonial-container.svg"
        />
        <img
          className="h-[1.181rem] w-[1.25rem] relative min-h-[1.188rem]"
          alt=""
          src="/testimonial-container.svg"
        />
        <img
          className="h-[1.181rem] w-[1.25rem] relative min-h-[1.188rem]"
          alt=""
          src="/testimonial-container.svg"
        />
      </div>
      <b className="self-stretch relative leading-[140%] mq450:text-[1rem] mq450:leading-[1.375rem]">
        {quote}
      </b>
      <div className="flex flex-row items-center justify-start gap-[1.25rem] max-w-full text-[1rem] mq750:flex-wrap">
        <img
          className="h-[3.5rem] w-[3.5rem] relative rounded-[50%] object-contain"
          alt=""
          src="/avatar-image@2x.png"
        />
        <div className="flex flex-col items-start justify-start">
          <div className="relative leading-[150%] font-semibold inline-block min-w-[4.938rem]">
            {clientAvatar}
          </div>
          <div className="relative leading-[150%]">{avatarPicture}</div>
        </div>
        <div className="h-[3.875rem] w-[0.063rem] relative box-border border-r-[1px] border-solid border-border-primary mq750:w-[6.25rem] mq750:h-[0.063rem] mq750:box-border mq750:border-t-[1px] mq750:border-solid mq750:border-border-primary" />
        <img
          className="h-[3.5rem] w-[8.75rem] relative overflow-hidden shrink-0"
          alt=""
          src="/logos12.svg"
        />
      </div>
    </div>
  );
};

Container.propTypes = {
  className: PropTypes.string,
  quote: PropTypes.string,
  clientAvatar: PropTypes.string,
  avatarPicture: PropTypes.string,
};

export default Container;
