import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const Footer = ({ className = "" }) => {
  return (
    <div
      className={`self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[5rem] px-[4rem] box-border gap-[5rem] max-w-full text-left text-[0.875rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="self-stretch flex flex-row items-start justify-start gap-[4rem] max-w-full mq1225:flex-wrap">
        <div className="w-[54rem] overflow-hidden shrink-0 flex flex-col items-start justify-start gap-[2rem] min-w-[54rem] max-w-full mq1050:min-w-full mq1225:flex-1">
          <img
            className="w h-[3.938rem] relative overflow-hidden shrink-0"
            alt="Company Logo"
            src="/komalpropertiesicon.png"
            style={{ width: "150x", height: "150px", objectFit: "contain" }}
          />
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.25rem]">
              <div className="self-stretch relative leading-[150%] font-semibold">
                Address:
              </div>
              <div className="self-stretch relative leading-[150%]">
                Komal Properties, Ganeshkhind Rd, Modibaug, <br />
                Shivajinagar, Pune, Maharashtra 411016
              </div>
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[0.25rem]">
              <div className="self-stretch relative leading-[150%] font-semibold">
                Contact:
              </div>
              <div className="self-stretch flex flex-col items-start justify-start text-link-primary">
                <div className="self-stretch relative [text-decoration:underline] leading-[150%]">
                  info@komalproperties.co.in
                </div>
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[0.75rem]">
              <a href="https://www.facebook.com/KomalProperties56/" target="_blank" rel="noopener noreferrer">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Facebook"
                  src="/icon--facebook.svg"
                />
              </a>
              <a href="https://www.instagram.com/komalproperties/" target="_blank" rel="noopener noreferrer">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Instagram"
                  src="/icon--instagram.svg"
                />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="X"
                  src="/icon--x.svg"
                />
              </a>
              <a href="https://www.linkedin.com/company/komalproperties/" target="_blank" rel="noopener noreferrer">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="LinkedIn"
                  src="/icon--linkedin.svg"
                />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="YouTube"
                  src="/icon--youtube.svg"
                />
              </a>
            </div>
          </div>
        </div>
        <div className="flex-1 overflow-hidden flex flex-row items-start justify-start gap-[1.5rem] min-w-[15.625rem] max-w-full text-[1rem] text-link-primary mq450:flex-wrap">
          <div className="flex-1 flex flex-col items-start justify-start gap-[0.75rem] min-w-[7.313rem]">
            <Link to="/" className="no-underline text-inherit">
              Home
            </Link>
            <Link to="/aboutuspage" className="no-underline text-inherit">
              Company
            </Link>
            <Link to="/projectspage" className="no-underline text-inherit">
              Projects
            </Link>
            <Link to="/landaggregationpage" className="no-underline text-inherit">
              Land Aggregation
            </Link>
            <Link to="/industrialconstructionpage" className="no-underline text-inherit">
              Industrial Construction
            </Link>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[0.75rem] min-w-[7.313rem]">
            <Link to="/plottingpage" className="no-underline text-inherit">
              Industrial Plotting
            </Link>
            <Link to="/vrrealtypage" className="no-underline text-inherit">
              VR Realty
            </Link>
            <Link to="/minespage" className="no-underline text-inherit">
              Mines
            </Link>
            <Link to="/ssrpowerpage" className="no-underline text-inherit">
              SSR Power
            </Link>
            <Link to="/blogpage" className="no-underline text-inherit">
              Blog
            </Link>
          </div>
        </div>
      </div>
      <div className="self-stretch flex flex-col items-start justify-start gap-[2rem] max-w-full">
        <div className="self-stretch h-[0.063rem] relative bg-border-primary box-border border-[1px] border-solid border-border-primary" />
        <div className="self-stretch flex flex-row items-start justify-between max-w-full gap-[1.25rem] mq750:flex-wrap">
          <div className="relative leading-[150%]">
            © 2024 Komal Properties. All rights reserved.
          </div>
          <div className="flex flex-row items-start justify-start gap-[1.5rem] max-w-full text-link-primary mq750:flex-wrap">
            <div className="relative [text-decoration:underline] leading-[150%] inline-block min-w-[6rem]">
              Privacy Policy
            </div>
            <div className="relative [text-decoration:underline] leading-[150%] inline-block min-w-[7.188rem]">
              Terms of Service
            </div>
            <div className="relative [text-decoration:underline] leading-[150%] inline-block min-w-[7.313rem]">
              Cookies Settings
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
