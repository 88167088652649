import PropTypes from "prop-types";
import { motion } from "framer-motion";

const ListItemHome1 = ({ className = "", heading, text }) => {
  return (
    <motion.div
      className={`flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[16.313rem] max-w-full text-left text-[2rem] text-text-primary font-text-small-normal ${className}`}
      whileHover={{ scale: 1.05 }}
      transition={{ duration: 0.3 }}
    >
      <img
        className="w-[3rem] h-[3rem] relative overflow-hidden shrink-0"
        alt=""
        src="/landaggregationicon.png"
      />
      <b className="self-stretch relative leading-[130%] mq750:text-[1.625rem] mq750:leading-[2.063rem] mq450:text-[1.188rem] mq450:leading-[1.563rem] hover:text-primary-green">
        {heading}
      </b>
      <div className="self-stretch relative text-[1rem] leading-[150%] text-text-secondary">
        {text}
      </div>
    </motion.div>
  );
};

ListItemHome1.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default ListItemHome1;
