import PropTypes from "prop-types";

const GallerySSRPower = ({ className = "", heading, galleryDescription }) => {
  return (
    <div
      className={`self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full text-center text-[3rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="w-[48rem] flex flex-col items-center justify-start gap-[1.5rem] max-w-full">
        <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
          {heading}
        </b>
        <div className="self-stretch relative text-[1.125rem] leading-[150%]">
          {galleryDescription}
        </div>
      </div>
      <div className="self-stretch grid flex-row items-start justify-start gap-[2rem] max-w-full grid-cols-[repeat(3,_minmax(312px,_1fr))] mq750:grid-cols-[minmax(312px,_1fr)] mq1050:justify-center mq1050:grid-cols-[repeat(2,_minmax(312px,_541px))]">
        <img
          className="relative max-w-full overflow-hidden max-h-full object-cover mq750:w-full"
          alt=""
          src="/ssrpowerwork.png"
        />
        <img
          className="relative max-w-full overflow-hidden max-h-full object-cover mq750:w-full"
          alt=""
          src="/ssrpowergallery2.png"
        />
        <img
          className="relative max-w-full overflow-hidden max-h-full object-cover mq750:w-full"
          alt=""
          src="/ssrgallery3.png"
        />
      </div>
    </div>
  );
};

GallerySSRPower.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  galleryDescription: PropTypes.string,
};

export default GallerySSRPower;
