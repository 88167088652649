import React, { useState } from "react";
import { Link } from "react-router-dom";
import Content from "../components/content";
import MenuItem from "../components/menuitem";
import BlogItem from "../components/blogitem";
import GallerySSRPower from "../components/galleryssrpower";
import Contact from "../components/contact";
import Footer from "../components/footer";
import PropTypes from "prop-types";

const SSRPowerPage = ({ className = "" }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div
      className={`absolute top-[0rem] left-[0rem] bg-background-color-primary flex flex-col items-start justify-start max-w-full text-center text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      {/* Header */}
      <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
        {isMenuVisible && (
          <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
            <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
              <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Komal Group
                  </div>
                  <Link to="/aboutuspage#leadership" className="no-underline text-inherit">
                    <MenuItem pageOne="Leadership" />
                  </Link>
                  <Link to="/aboutuspage#timeline" className="no-underline text-inherit">
                    <MenuItem pageOne="Past and Future" />
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit">
                    <MenuItem pageOne="Mines" />
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit">
                    <MenuItem pageOne="SSR Power" />
                  </Link>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Offerings
                  </div>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Parks" />
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit">
                    <MenuItem pageOne="VR Realty Advisory" />
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Construction" />
                  </Link>
                  <Link to="/landaggregationpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Strategic Land Aggregations" />
                  </Link>
                </div>
              </div>
              <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
                <div className="self-stretch relative text-[0.875rem] leading-[150%] font-semibold">
                  Downloads
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
                  <Link to="/developmentmaps" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/govt-maps.png"
                      articleTitle="Development Maps"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/gr.png"
                      articleTitle="Government Resolutions"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/brochure.png"
                      articleTitle="Corporate Brochure"
                      readMore="Download"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/developmentmaps" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* Hero Section */}
      <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full text-[3.5rem] text-background-color-primary relative">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src="/hydrossrbanner.png"
          alt="SSR Power Banner"
        />
        <div className="relative z-10 self-stretch flex flex-row flex-wrap items-start justify-start gap-[5rem] max-w-full bg-black bg-opacity-50 p-[2rem]">
          <div className="flex-1 flex flex-col items-start justify-start min-w-[25rem] max-w-full mq750:min-w-full">
            <b className="self-stretch relative leading-[120%] mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]">
              Empowering Sustainable Energy
            </b>
            <div className="self-stretch relative text-[1.125rem] leading-[150%]">
              SSR Power, a Pune-based hydro power generation company, is
              affiliated with Komal Properties.
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[2rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
              <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
                Discover SSR Power's Impressive Hydro Power Generation
                Capabilities and Projects
              </b>
              <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                SSR Power is a Pune-based hydro power generation company, owned
                by the Komal Properties group. With a strong focus on
                sustainable energy solutions, we have successfully developed and
                operated a portfolio of hydro power projects.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-[1.25rem] mq750:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[140%] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Our Expertise
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  We specialize in designing and constructing state-of-the-art
                  hydro power plants, harnessing the power of water.
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[140%] mq450:text-[1rem] mq450:leading-[1.375rem]">
                  Our Projects
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  Explore our portfolio of successful hydro power projects,
                  delivering clean and renewable energy.
                </div>
              </div>
            </div>
          </div>
          <img
            className="h-[40rem] flex-1 relative max-w-full overflow-hidden object-cover min-w-[25rem] mq750:min-w-full"
            alt="Hydro Power Projects"
            src="/hydrossrbg.png"
          />
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full">
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[5rem] max-w-full">
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-start min-w-[25rem] max-w-full mq750:min-w-full">
            <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
              SSR Power: Empowering the Community with Sustainable Hydro Power
              Generation
            </b>
          </div>
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[2rem] min-w-[25rem] max-w-full text-[1.125rem] mq750:min-w-full">
            <div className="self-stretch relative leading-[150%]">
              Discover the impressive achievements of SSR Power, a Pune-based
              hydro power generation company owned by Komal Properties group.
              With a focus on sustainable energy production, SSR Power has made
              significant contributions to the local grid.
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-[3rem] mq750:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  50% increase
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  in energy output compared to the previous year
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  50% reduction
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  in carbon emissions through sustainable practices
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GallerySSRPower
        heading="SSR Power Gallery"
        galleryDescription="Explore our hydro power projects and facilities through images and videos."
      />
      <Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/line-1.svg"
        emailInput="/line-2.svg"
      />
      <Footer />
    </div>
  );
};

SSRPowerPage.propTypes = {
  className: PropTypes.string,
};

export default SSRPowerPage;
