import PropTypes from "prop-types";

const Layout4VRRealty = ({
  className = "",
  subheading,
  heading,
  loremIpsumDolorSitAmetCon,
  featureOne,
  heading1,
  text,
  featureTwo,
  featureThree,
  featureFour,
}) => {
  return (
    <div
      className={`self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-center py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full text-center text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="w-[48rem] flex flex-col items-start justify-start gap-[1rem] max-w-full">
        <div className="relative leading-[150%] font-semibold inline-block min-w-[3.813rem]">
          {subheading}
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-left text-[3rem]">
          <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
            {heading}
          </b>
          <div className="self-stretch relative text-[1.125rem] leading-[150%]">
            {loremIpsumDolorSitAmetCon}
          </div>
        </div>
      </div>
      <div className="self-stretch box-border overflow-hidden flex flex-col items-start justify-start max-w-full text-[1.5rem] border-[1px] border-solid border-border-primary">
        <div className="self-stretch flex flex-row items-start justify-start max-w-full">
          <div className="flex-1 flex flex-row items-start justify-start max-w-[calc(100%_-_249px)] [row-gap:20px] mq1050:flex-wrap mq1050:max-w-full">
            <div className="h-[45rem] overflow-hidden flex flex-col items-center justify-between py-[2rem] px-[1.5rem] box-border">
              <b className="self-stretch relative leading-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                01
              </b>
              <b className="h-[2.125rem] relative leading-[140%] inline-block text-left [transform:_rotate(-90deg)] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                {featureOne}
              </b>
            </div>
            <div className="h-[45rem] w-[40rem] flex flex-col items-start justify-start py-[4rem] px-[3rem] box-border gap-[3rem] max-w-full text-left text-[2.5rem]">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
                  {heading1}
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  {text}
                </div>
              </div>
              <img
                className="self-stretch h-[25rem] relative max-w-full overflow-hidden shrink-0 object-cover"
                alt=""
                src="/placeholder-image@2x.png"
              />
            </div>
          </div>
          <div className="flex flex-row items-start justify-start [row-gap:20px] mq1050:hidden mq450:flex-wrap">
            <div className="h-[45rem] w-[0.063rem] relative bg-border-primary box-border border-[1px] border-solid border-border-primary mq450:w-[6.25rem] mq450:h-[0.063rem] mq450:box-border mq450:border-t-[1px] mq450:border-solid mq450:border-border-primary mq450:border-b-[1px]" />
            <div className="h-[45rem] overflow-hidden flex flex-col items-center justify-between py-[2rem] px-[1.5rem] box-border">
              <b className="self-stretch relative leading-[2.125rem] inline-block min-w-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                02
              </b>
              <b className="h-[2.125rem] relative leading-[140%] inline-block text-left [transform:_rotate(-90deg)] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                {featureTwo}
              </b>
            </div>
            <div className="h-[45rem] w-[0.063rem] relative bg-border-primary box-border border-[1px] border-solid border-border-primary mq450:w-[6.25rem] mq450:h-[0.063rem] mq450:box-border mq450:border-t-[1px] mq450:border-solid mq450:border-border-primary mq450:border-b-[1px]" />
            <div className="h-[45rem] overflow-hidden flex flex-col items-center justify-between py-[2rem] px-[1.5rem] box-border">
              <b className="self-stretch relative leading-[2.125rem] inline-block min-w-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                03
              </b>
              <b className="relative leading-[140%] text-left [transform:_rotate(-90deg)] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                {featureThree}
              </b>
            </div>
            <div className="h-[45rem] w-[0.063rem] relative bg-border-primary box-border border-[1px] border-solid border-border-primary mq450:w-[6.25rem] mq450:h-[0.063rem] mq450:box-border mq450:border-t-[1px] mq450:border-solid mq450:border-border-primary mq450:border-b-[1px]" />
            <div className="h-[45rem] overflow-hidden flex flex-col items-center justify-between py-[2rem] px-[1.5rem] box-border">
              <b className="self-stretch relative leading-[2.125rem] inline-block min-w-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                04
              </b>
              <b className="h-[2.125rem] relative leading-[140%] inline-block text-left [transform:_rotate(-90deg)] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
                {featureFour}
              </b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Layout4VRRealty.propTypes = {
  className: PropTypes.string,
  subheading: PropTypes.string,
  heading: PropTypes.string,
  loremIpsumDolorSitAmetCon: PropTypes.string,
  featureOne: PropTypes.string,
  heading1: PropTypes.string,
  text: PropTypes.string,
  featureTwo: PropTypes.string,
  featureThree: PropTypes.string,
  featureFour: PropTypes.string,
};

export default Layout4VRRealty;
