import { useMemo } from "react";
import PropTypes from "prop-types";

const BlogItem = ({
  className = "",
  articleTitle,
  articleDescription,
  readMore,
  propFlexDirection,
  propFlex,
}) => {
  const blogItemStyle = useMemo(() => {
    return {
      flexDirection: propFlexDirection,
      flex: propFlex,
    };
  }, [propFlexDirection, propFlex]);

  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-left text-[1rem] text-link-primary font-text-small-normal mq750:flex-wrap ${className}`}
      style={blogItemStyle}
    >
      <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[10.063rem]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[0.25rem]">
          <div className="self-stretch relative leading-[150%] font-semibold">
            {articleTitle}
          </div>
          <div className="self-stretch relative text-[0.875rem] leading-[150%] text-text-primary">
            {articleDescription}
          </div>
        </div>
        <div className="relative text-[0.875rem] [text-decoration:underline] leading-[150%] inline-block min-w-[4.188rem]">
          {readMore}
        </div>
      </div>
    </div>
  );
};

BlogItem.propTypes = {
  className: PropTypes.string,
  articleTitle: PropTypes.string,
  articleDescription: PropTypes.string,
  readMore: PropTypes.string,

  /** Style props */
  propFlexDirection: PropTypes.any,
  propFlex: PropTypes.any,
};

export default BlogItem;
