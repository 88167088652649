import PropTypes from "prop-types";

const Column2 = ({ className = "", heading, text, iframeSrc }) => {
  return (
    <div
      className={`flex-1 flex flex-col items-center justify-start gap-[2rem] min-w-[25.375rem] max-w-full text-center text-[2rem] text-text-primary font-text-small-normal mq750:min-w-full ${className}`}
    >
      <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] max-w-full">
        <div className="w-[27.563rem] flex flex-col items-center justify-start gap-[1rem] max-w-full">
          <b className="self-stretch relative leading-[2.625rem] mq750:text-[1.625rem] mq750:leading-[2.063rem] mq450:text-[1.188rem] mq450:leading-[1.563rem]">
            {heading}
          </b>
        </div>
      </div>
      <div className="self-stretch h-[24rem] relative max-w-full overflow-hidden shrink-0">
        <iframe
          src={iframeSrc}
          width="600"
          height="450"
          style={{ border: 0, width: "100%", height: "100%" }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

Column2.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
  iframeSrc: PropTypes.string,
};

export default Column2;
