import PropTypes from "prop-types";
import { useState } from "react";
import { motion } from "framer-motion";

const Contact = ({
  className = "",
  loremIpsumDolorSitAmetCon,
  hellorelumeio,
  sampleStSydneyNSW2000AU,
  typeYourMessage,
}) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [agreement, setAgreement] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!agreement) {
      alert("You must agree to the terms.");
      return;
    }
    const formData = { name, email, message };
    try {
      const response = await fetch("/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Message sent successfully!");
        setName("");
        setEmail("");
        setMessage("");
        setAgreement(false);
      } else {
        alert("Failed to send message.");
      }
    } catch (error) {
      alert("Error sending message.");
    }
  };

  return (
    <div
      className={`self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full text-center text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      <form onSubmit={handleSubmit} className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[5rem] max-w-full">
        <div className="flex-1 flex flex-col items-start justify-start gap-[2rem] min-w-[25rem] max-w-full mq750:min-w-full">
          <div className="self-stretch flex flex-col items-start justify-start gap-[1rem]">
            <div className="relative leading-[150%] font-semibold inline-block min-w-[4.313rem]">
              Experience Excellence
            </div>
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-left text-[3rem]">
              <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                Get in Touch
              </b>
              <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                {loremIpsumDolorSitAmetCon}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start justify-start py-[0.5rem] px-[0rem] box-border gap-[1rem] max-w-full text-left">
            <div className="flex flex-row items-start justify-start gap-[1rem]">
              <motion.img
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
                className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                alt=""
                src="/icon--envelope.svg"
              />
              <div className="relative leading-[150%] whitespace-nowrap">
                {hellorelumeio}
              </div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[1rem]">
              <motion.img
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
                className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                alt=""
                src="/icon--phone.svg"
              />
              <div className="relative leading-[150%]">+91 8888585656</div>
            </div>
            <div className="flex flex-row items-start justify-start gap-[1rem]">
              <motion.img
                whileHover={{ scale: 1.2 }}
                transition={{ duration: 0.3 }}
                className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                alt=""
                src="/icon--map.svg"
              />
              <div className="relative leading-[150%]">
                Gat no 222, Nanekarwadi, Chakan, Maharashtra 410501
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[25rem] max-w-full text-left mq750:min-w-full">
          <div className="self-stretch h-[5rem] flex flex-col items-start justify-start gap-[0.5rem] max-w-full">
            <div className="self-stretch relative leading-[150%]">Name</div>
            <motion.div
              whileFocus={{ scale: 1.02, borderColor: "#015730" }}
              className="self-stretch bg-background-color-primary box-border flex flex-row items-center justify-start p-[0.75rem] max-w-full text-color-neutral-neutral border-[1px] border-solid border-link-primary"
            >
              <input
                className="w-full h-full bg-transparent border-none outline-none"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </motion.div>
          </div>
          <div className="self-stretch h-[5rem] flex flex-col items-start justify-start gap-[0.5rem] max-w-full shrink-0">
            <div className="self-stretch relative leading-[150%]">Email</div>
            <motion.div
              whileFocus={{ scale: 1.02, borderColor: "#015730" }}
              className="self-stretch bg-background-color-primary box-border flex flex-row items-center justify-start p-[0.75rem] max-w-full text-color-neutral-neutral border-[1px] border-solid border-link-primary"
            >
              <input
                className="w-full h-full bg-transparent border-none outline-none"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </motion.div>
          </div>
          <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] max-w-full shrink-0">
            <div className="self-stretch relative leading-[150%] shrink-0">
              Message
            </div>
            <motion.div
              whileFocus={{ scale: 1.02, borderColor: "#015730" }}
              className="self-stretch bg-background-color-primary box-border flex flex-row items-start justify-start py-[0.75rem] px-[0.687rem] relative min-h-[11.375rem] max-w-full shrink-0 text-color-neutral-neutral border-[1px] border-solid border-link-primary"
            >
              <textarea
                className="w-full h-full bg-transparent border-none outline-none resize-none"
                placeholder="Enter your message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </motion.div>
          </div>
          <div className="flex flex-row items-center justify-start pt-[0rem] px-[0rem] pb-[1rem] gap-[0.5rem] shrink-0 text-[0.875rem]">
            <input
              type="checkbox"
              checked={agreement}
              onChange={() => setAgreement(!agreement)}
              className="h-[1.25rem] w-[1.25rem] relative bg-background-color-primary box-border overflow-hidden shrink-0 border-[1px] border-solid border-border-primary cursor-pointer"
            />
            <div className="relative leading-[150%]">I agree to the Terms</div>
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="bg-link-primary flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] shrink-0 text-background-color-primary border-[1px] border-solid cursor-pointer"
            style={{ backgroundColor: "#015730", borderColor: "#015730" }}
            type="submit"
          >
            <div className="relative leading-[150%] inline-block min-w-[3.75rem]">
              Submit
            </div>
          </motion.button>
        </div>
      </form>
    </div>
  );
};

Contact.propTypes = {
  className: PropTypes.string,
  loremIpsumDolorSitAmetCon: PropTypes.string,
  hellorelumeio: PropTypes.string,
  sampleStSydneyNSW2000AU: PropTypes.string,
  typeYourMessage: PropTypes.string,
  namePlaceholder: PropTypes.string,
  emailInput: PropTypes.string,
};

export default Contact;
