import PropTypes from "prop-types";

const SectionTitleHome = ({
  className = "",
  heading,
  text,
}) => {
  return (
    <div
      className={`w-[23.75rem] flex flex-col items-start justify-start gap-[1.5rem] min-w-[23.75rem] max-w-full text-center text-[1rem] text-text-primary font-text-small-normal mq750:min-w-full mq1225:flex-1 ${className}`}
    >
      <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-left text-[3rem]">
        <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
          {heading}
        </b>
        <div className="self-stretch relative text-[1.125rem] leading-[150%]">
          {text}
        </div>
      </div>
    </div>
  );
};

SectionTitleHome.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default SectionTitleHome;
