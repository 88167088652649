import axios from 'axios';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Content from '../components/content';
import MenuItem from '../components/menuitem';
import BlogItem from '../components/blogitem';
import Contact from '../components/contact';
import Footer from '../components/footer';
import PropTypes from 'prop-types';

const ProjectsPage = ({ className = "", companyName }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);
  const [activeTab, setActiveTab] = useState("Industrial Construction");
  const [projects, setProjects] = useState({
    "Industrial Construction": [],
    "Strategic Land Aggregation": [],
    "Industrial Plotting": [],
  });

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get('http://localhost:3000/projects', {
          params: {
            companyName: companyName // Corrected usage of companyName prop
          }
        });
        const projectsData = response.data.reduce((acc, project) => {
          if (!acc[project.category]) {
            acc[project.category] = [];
          }
          acc[project.category].push({
            title: project.projectName,
            description: project.description,
            image: `http://localhost:3000${project.imagePath}`
          });
          return acc;
        }, {
          "Industrial Construction": [],
          "Strategic Land Aggregation": [],
          "Industrial Plotting": [],
        });
        setProjects(projectsData);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };

    fetchProjects();
  }, [companyName]);

  const economicStats = [
    { number: "30%", description: "Contribution to India's Industrial Output" },
    { number: "25%", description: "Increase in Industrial Investments" },
    { number: "500+", description: "New Companies in the Last Decade" },
    { number: "3rd", description: "Largest Industrial Hub in India" },
  ];

  const manufacturingContributions = [
    { number: "40%", description: "Automobile Manufacturing" },
    { number: "20%", description: "Electronics Production" },
    { number: "15%", description: "Pharmaceuticals Manufacturing" },
    { number: "25%", description: "Engineering Goods" },
  ];

  return (
    <div className={`absolute top-0 left-0 bg-background-color-primary overflow-hidden flex flex-col items-start justify-start max-w-full text-left text-[1rem] text-text-primary font-text-small-normal ${className}`}>
      <div className="self-stretch bg-background-color-primary box-border overflow-hidden flex flex-col items-center justify-start max-w-full border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
        {isMenuVisible && (
          <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
            <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
              <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Komal Group
                  </div>
                  <Link to="/aboutuspage#leadership" className="no-underline text-inherit">
                    <MenuItem pageOne="Leadership" />
                  </Link>
                  <Link to="/aboutuspage#timeline" className="no-underline text-inherit">
                    <MenuItem pageOne="Past and Future" />
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit">
                    <MenuItem pageOne="Mines" />
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit">
                    <MenuItem pageOne="SSR Power" />
                  </Link>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Offerings
                  </div>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Parks" />
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit">
                    <MenuItem pageOne="VR Realty Advisory" />
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Construction" />
                  </Link>
                  <Link to="/landaggregationpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Strategic Land Aggregations" />
                  </Link>
                </div>
              </div>
              <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
                <div className="self-stretch relative text-[0.875rem] leading-[150%] font-semibold">
                  Downloads
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
                  <Link to="/developmentmaps" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/govt-maps.png"
                      articleTitle="Development Maps"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/gr.png"
                      articleTitle="Government Resolutions"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/brochure.png"
                      articleTitle="Corporate Brochure"
                      readMore="Download"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* Hero Section */}
      <div className="relative w-full h-[30rem]">
        <img src="/projectspage.png" alt="Hero" className="object-cover w-full h-full" />
        <div className="absolute inset-0 flex items-center justify-center">
          <h1 className="text-white text-[3rem]">Advantage Komal</h1>
        </div>
      </div>

      {/* Pune Section */}
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start pt-[5rem] pb-[3rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <div className="self-stretch flex flex-row items-start justify-start gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col justify-center">
            <h2 className="text-[3.5rem]">Pune</h2>
            <p className="text-[1.125rem]">
              Pune, known for its vibrant industrial areas such as Chakan and Talegaon, has become a major industrial hub in India. These areas host numerous multinational companies and offer excellent
              infrastructure and connectivity. The city's strategic location, skilled workforce, and supportive government policies make it an ideal destination for industrial investments. Pune's industrial
              landscape is diverse, including automotive, electronics, pharmaceuticals, and engineering sectors, contributing significantly to India's industrial output.
            </p>
          </div>
          <div className="flex-1">
            <img src="/placeholder-image@2x.png" alt="Pune Industrial Hub" className="object-cover w-full h-full" />
          </div>
        </div>
      </div>

      {/* Quick Statistics Section */}
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start pt-[5rem] pb-[3rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <h2 className="text-[3.5rem]">Quick Statistics</h2>
        <div className="grid grid-cols-2 gap-8">
          {economicStats.map((stat, index) => (
            <div key={index} className="bg-white p-8 text-center shadow-md">
              <h3 className="text-[2rem]">{stat.number}</h3>
              <p className="text-[1rem]">{stat.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Manufacturing Contributions Section */}
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start pt-[5rem] pb-[3rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <h2 className="text-[3.5rem]">Manufacturing Contributions</h2>
        <div className="grid grid-cols-2 gap-8">
          {manufacturingContributions.map((contribution, index) => (
            <div key={index} className="bg-white p-8 text-center shadow-md">
              <h3 className="text-[2rem]">{contribution.number}</h3>
              <p className="text-[1rem]">{contribution.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Komal Properties, Pune Section */}
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start pt-[5rem] pb-[3rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <h2 className="text-[3.5rem]">Komal Properties, Pune</h2>
        <div className="flex flex-row items-center justify-center gap-[1rem]">
          {["Industrial Construction", "Strategic Land Aggregation", "Industrial Plotting"].map((tab) => (
            <div key={tab} className={`cursor-pointer py-[0.5rem] px-[1rem] ${activeTab === tab ? "bg-green-600 text-white" : "bg-gray-200 text-black"}`} onClick={() => handleTabClick(tab)}>
              {tab}
            </div>
          ))}
        </div>
        <div className="self-stretch flex flex-row items-start justify-start gap-[5rem] max-w-full overflow-x-auto">
          {projects[activeTab].map((project, index) => (
            <div key={index} className="flex-none w-[20rem] p-[1rem] bg-white shadow-lg m-[0.5rem]">
              <img src={project.image} alt={project.title} className="object-cover w-full h-48" />
              <h3 className="mt-[1rem] text-[1.5rem]">{project.title}</h3>
              <p className="mt-[0.5rem] text-[1rem]">{project.description}</p>
            </div>
          ))}
        </div>
      </div>

      <Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/line-1.svg"
        emailInput="/line-2.svg"
      />
      <Footer />
    </div>
  );
};

ProjectsPage.propTypes = {
  className: PropTypes.string,
  companyName: PropTypes.string.isRequired,
};

export default ProjectsPage;
