import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import CountUp from 'react-countup';
import MenuItem from "../components/menuitem";
import BlogItem from "../components/blogitem";
import Card from "../components/card";
import SectionTitle from "../components/sectiontitle";
import ListItemHome1 from "../components/listitemhome1";
import ListItemHome2 from "../components/listitemhome2";
import ListItemHome3 from "../components/listitemhome3";
import ListItemHome4 from "../components/listitemhome4";
import Card2 from "../components/card2";
import LogoHome from "../components/logohome";
import Contact from "../components/contact";
import Footer from "../components/footer";
import PropTypes from "prop-types";
import SectionTitleHome from "../components/sectiontitlehome";

const HomePage = ({ className = "" }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div
      className={`absolute top-[0rem] left-[0rem] bg-background-color-primary flex flex-col items-start justify-start max-w-full text-center text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      {/* Header */}
      <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
{isMenuVisible && (
  <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
    <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
      <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
        <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
          <div className="self-stretch relative leading-[150%] font-semibold">
            Komal Group
          </div>
          <Link to="/aboutuspage#leadership" className="no-underline text-inherit">
            <MenuItem pageOne="Leadership" />
          </Link>
          <Link to="/aboutuspage#timeline" className="no-underline text-inherit">
            <MenuItem pageOne="Past and Future" />
          </Link>
          <Link to="/minespage" className="no-underline text-inherit">
            <MenuItem pageOne="Mines" />
          </Link>
          <Link to="/ssrpowerpage" className="no-underline text-inherit">
            <MenuItem pageOne="SSR Power" />
          </Link>
        </div>
        <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
          <div className="self-stretch relative leading-[150%] font-semibold">
            Offerings
          </div>
          <Link to="/plottingprojectspage" className="no-underline text-inherit">
            <MenuItem pageOne="Industrial Parks" />
          </Link>
          <Link to="/vrrealtypage" className="no-underline text-inherit">
            <MenuItem pageOne="VR Realty Advisory" />
          </Link>
          <Link to="/industrialconstructionpage" className="no-underline text-inherit">
            <MenuItem pageOne="Industrial Construction" />
          </Link>
          <Link to="/landaggregationpage" className="no-underline text-inherit">
            <MenuItem pageOne="Strategic Land Aggregations" />
          </Link>
        </div>
      </div>
      <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
        <div className="self-stretch relative text-[0.875rem] leading-[150%] font-semibold">
          Downloads
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
          <Link to="/developmentmaps" className="no-underline text-inherit">
            <BlogItem
              placeholderImage="/govt-maps.png"
              articleTitle="Development Maps"
              readMore="Learn More"
            />
          </Link>
          <Link to="/governmentreports" className="no-underline text-inherit">
            <BlogItem
              placeholderImage="/gr.png"
              articleTitle="Government Resolutions"
              readMore="Learn More"
            />
          </Link>
          <Link to="/corporatebrochure" className="no-underline text-inherit">
            <BlogItem
              placeholderImage="/brochure.png"
              articleTitle="Corporate Brochure"
              readMore="Download"
            />
          </Link>
        </div>
      </div>
    </div>
  </div>
)}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* Main Content */}
      <div className="relative w-full overflow-hidden min-h-[56.25rem]">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src="/industrial_park.mp4"
          autoPlay
          muted
          loop
        />
        <div
          className="relative z-10 flex flex-col items-center justify-center min-h-[56.25rem] text-[3.5rem] text-background-color-primary bg-black bg-opacity-50"
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          }}
        >
          <motion.div
            className="w-[50.5rem] flex flex-col items-center justify-start p-[1.25rem] box-border gap-[1.5rem] max-w-full"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem]">
              <b
                className="self-stretch relative leading-[120%] mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]"
                style={{
                  fontSize: "3.5rem",
                  fontWeight: "bold",
                  color: "#fff",
                }}
              >
                Discover <span style={{ color: "#015730" }}>Excellence</span> in Industrial Real Estate with Komal Properties
              </b>
              <div
                className="self-stretch relative text-[1.125rem] leading-[150%]"
                style={{ color: "#fff" }}
              >
                For over 23 years, we have been dedicated to driving the growth
                of your vision, workspace, and business in Pune
              </div>
            </div>
            <div className="h-[4rem] flex flex-row items-start justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1rem] text-left text-[1rem]">
              <div
                className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] whitespace-nowrap border-[1px] border-solid transition-all duration-300 ease-in-out transform hover:scale-105"
                style={{
                  backgroundColor: "#015730",
                  borderColor: "#015730",
                  color: "#fff",
                  cursor: "pointer",
                }}
              >
                <div className="relative leading-[150%] inline-block min-w-[5.688rem]">
                  <Link to="/aboutuspage" className="no-underline text-inherit">
                    Learn More
                  </Link>
                </div>
              </div>
              <div
                className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] border-[1px] border-solid transition-all duration-300 ease-in-out transform hover:scale-105"
                style={{ borderColor: "#015730", color: "#015730", cursor: "pointer" }}
              >
                <div className="relative leading-[150%] inline-block min-w-[3.563rem]">
                  Button
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>

{/* Partners in your success section */}
      <motion.div
        className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[7rem] px-[4rem] box-border max-w-full text-center text-[1rem] text-text-primary font-text-small-normal"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[1.5rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1rem]">
              <div className="relative leading-[150%] font-semibold">
                Partners in your success
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-left text-[3rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  Transforming Industrial Real Estate with Innovation and Expertise
                </b>
                <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                  Welcome to Komal Properties. Our mission is to provide exceptional solutions and services to meet the diverse needs of our clients. With a strong emphasis on innovation and expertise, we are dedicated to reshaping the industrial real estate landscape.
                </div>
              </div>
            </div>
            <div className="h-[4rem] flex flex-row items-center justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1.5rem] text-left">
              <div className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] border-[1px] border-solid border-link-primary hover:bg-link-primary hover:text-background-color-primary transition-colors duration-300 no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  <Link to="/aboutuspage" className="no-underline text-inherit">
                    About Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <img
            className="h-[40rem] flex-1 relative max-w-full overflow-hidden object-cover min-w-[25rem] mq750:min-w-full"
            alt=""
            src="/industrial-park-bldg.jpg"
          />
        </div>
      </motion.div>

      {/* Our Portfolio section */}
      <motion.div
        className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[7rem] px-[4rem] box-border max-w-full text-left text-[2.5rem]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, ease: "easeOut" }}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[5rem] max-w-full">
          <img
            className="h-[40rem] flex-1 relative max-w-full overflow-hidden object-cover min-w-[25rem] mq750:min-w-full"
            alt="Map of Chakan-Talegaon"
            src="/mapchakan-talegaon.png"
          />
          <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[2rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
              <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
                Our Portfolio
              </b>
              <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                At Komal Properties, we take pride in our impressive track record of delivering exceptional real estate solutions. With a focus on quality and innovation, we have successfully completed numerous projects that have made a significant impact in the industry. Our commitment to excellence sets us apart from the competition.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-[3rem] mq750:flex-wrap">
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  <CountUp start={0} end={1500000} duration={2.75} separator="," />+
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  Industrial Square feet developed
                </div>
              </div>
              <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  <CountUp start={0} end={7000} duration={2.75} separator="," />+
                </b>
                <div className="self-stretch relative text-[1rem] leading-[150%]">
                  Customers Served
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
      
{/* Our Projects section */}
<div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full">
  <div className="w-[48rem] flex flex-col items-center justify-start gap-[1rem] max-w-full">
    <div className="relative leading-[150%] font-semibold inline-block min-w-[4.5rem]">
    </div>
    <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-[3rem]">
      <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
        Recent Projects
      </b>
      <div className="self-stretch relative text-[1.125rem] leading-[150%]">
        Some of our Grade-A Turnkey Projects
      </div>
    </div>
  </div>
  <motion.div
    className="self-stretch flex flex-row items-center justify-start max-w-full shrink-0"
    initial={{ opacity: 0, y: 50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <div className="self-stretch flex flex-row items-start justify-start gap-[3rem] max-w-full">
      <motion.div
        className="w-full h-full"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <Card
          placeholderImage="/modernwarehouseproject1.jpg"
          heading="Project One"
        />
      </motion.div>
      <motion.div
        className="w-full h-full"
        whileHover={{ scale: 1.05 }}
        transition={{ duration: 0.3 }}
      >
        <Card
          placeholderImage="/modernwarehouseproject2.jpg"
          heading="Project Two"
        />
      </motion.div>
    </div>
  </motion.div>
  <div className="w-[7.063rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.437rem] shrink-0 text-[1rem] border-[1px] border-solid transition-all duration-300 ease-in-out transform hover:scale-105 mt-[2rem]" style={{ borderColor: "#015730" }}>
    <Link to="/projectspage" className="no-underline text-inherit">
      <div className="relative leading-[150%] inline-block min-w-[4rem]">
        View All
      </div>
    </Link>
  </div>
</div>

{/* Our Services section */}
<div className="self-stretch bg-background-color-primary overflow-hidden flex flex-row items-start justify-center py-[7rem] px-[4rem] box-border max-w-full">
  <div className="flex-1 flex flex-row items-start justify-start gap-[5rem] max-w-full mq1225:flex-wrap">
    <SectionTitleHome
      heading="Services That Empower Your Real Estate Goals"
      text="At Komal Properties, we offer a range of services to support your industrial real estate needs. From land aggregation to industrial and warehouse construction, strategic consulting and advisory, and industrial and residential plotting, our team is dedicated to helping you achieve success."
    />
    <div className="flex-1 flex flex-col items-start justify-start gap-[4rem] min-w-[34.625rem] max-w-full text-left text-[2rem] mq750:min-w-full">
      <div className="self-stretch flex flex-row items-start justify-start gap-[3rem] max-w-full mq1050:flex-wrap">
        <motion.div
          className="w-full"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <Link to="/landaggregationpage" style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemHome1
              heading="Land Aggregation Services"
              text="We specialize in acquiring and aggregating land for industrial purposes, ensuring that you have the right location for your business."
            />
          </Link>
        </motion.div>
        <motion.div
          className="w-full"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <Link to="/industrialconstructionpage" style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemHome2
              heading="Industrial and Warehouse Construction"
              text="Our experienced team delivers high-quality construction services for industrial and warehouse facilities, tailored to your specific requirements."
            />
          </Link>
        </motion.div>
      </div>
      <div className="self-stretch flex flex-row items-start justify-start gap-[3rem] max-w-full mq1050:flex-wrap">
        <motion.div
          className="w-full"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <Link to="/vrrealtypage" style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemHome3
              heading="Strategic Consulting and Advisory"
              text="We provide strategic consulting and advisory services to help you make informed decisions and optimize your real estate investments."
            />
          </Link>
        </motion.div>
        <motion.div
          className="w-full"
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <Link to="/plottingprojectspage" style={{ textDecoration: 'none', color: 'inherit' }}>
            <ListItemHome4
              heading="Industrial and Residential Plotting"
              text="With our expertise in industrial and residential plotting, we can assist you in developing profitable real estate projects."
            />
          </Link>
        </motion.div>
      </div>
    </div>
  </div>
</div>

{/* Our Blog section */}
<div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full text-left">
  <div className="self-stretch flex flex-row items-end justify-between max-w-full gap-[1.25rem] mq1050:flex-wrap mq1050:justify-center">
    <div className="w-[48rem] flex flex-col items-start justify-start gap-[1rem] max-w-full">
      <div className="relative leading-[150%] font-semibold inline-block min-w-[3.188rem]">
      </div>
      <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-[3rem] text-[#004D40]">
        <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
          Discover Our Insights
        </b>
        <div className="self-stretch relative text-[1.125rem] leading-[150%] text-[#4F4F4F]">
          Stay updated with our informative articles
        </div>
      </div>
    </div>
    <div className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.437rem] border-[1px] border-solid transition-all duration-300 ease-in-out transform hover:scale-105 hover:bg-[#004D40] hover:text-white" style={{ borderColor: "#004D40", color: "#004D40" }}>
      <div className="relative leading-[150%] inline-block min-w-[3.875rem]">
        View all
      </div>
    </div>
  </div>
  <div className="self-stretch flex flex-row items-start justify-start gap-[2rem] max-w-full text-[0.875rem]">
    <div className="relative w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <Card2
        heading="The Importance of Real Estate Investment"
        text="Learn how real estate investment can help you achieve financial success."
      />
    </div>
    <div className="relative w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <Card2
        heading="The Future of Sustainable Architecture"
        text="Explore the innovative designs and technologies shaping sustainable architecture."
      />
    </div>
    <div className="relative w-full h-full transition-transform duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
      <Card2
        heading="The Benefits of Industrial Real Estate"
        text="Discover why investing in industrial real estate can be a lucrative opportunity."
      />
    </div>
  </div>
</div>


      {/* Brands We Serve section */}
      <LogoHome heading="Brands We Serve" />

      {/* Contact Us section */}       
<Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/name-placeholder.svg"
        emailInput="/email-input.svg"
        buttonColor="#015730"
/>


      <Footer />
    </div>
  );
};

HomePage.propTypes = {
  className: PropTypes.string,
};

export default HomePage;
