import PropTypes from "prop-types";
import { motion } from "framer-motion";

const LogoHome = ({ className = "", heading }) => {
  return (
    <div
      className={`self-stretch bg-background-color-primary overflow-hidden flex flex-col items-center justify-start py-[5rem] px-[4rem] box-border gap-[1.5rem] max-w-full text-center text-[1.125rem] text-text-primary font-text-small-normal ${className}`}
    >
      <b className="w-[35rem] relative leading-[150%] inline-block max-w-full text-[3rem] text-[#004D40]">
        {heading}
      </b>
      <motion.div 
        className="self-stretch flex flex-col items-center justify-center gap-[2rem]"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}
      >
        <div className="self-stretch flex flex-row flex-wrap items-center justify-center gap-[3rem]">
          {[
            "/autolinelogonew.png",
            "/bentelernewlogo.svg",
            "/boschkomallogo.png",
            "/forbesmarshallbluelogo.png",
            "/cieindialogo.png",
            "/phillipslogo.png",
            "/suzlonnewnewlogo.svg",
            "/tvsnewlogo.png",
            "/volkswagennewnewlogo.svg",
            "/toyonewlogo.svg",
            "/indospacenewnewlogo.png",
          ].map((logo, index) => (
            <motion.img
              key={index}
              className="h-[3.5rem] w-[8.75rem] relative overflow-hidden shrink-0 transition-transform duration-300 ease-in-out transform hover:scale-110 hover:shadow-lg"
              alt=""
              src={logo}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            />
          ))}
        </div>
      </motion.div>
    </div>
  );
};

LogoHome.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
};

export default LogoHome;
