import PropTypes from "prop-types";

const ListItem4 = ({
  className = "",
  subheadingOne,
  siteIdentification,
  dealNegotiation,
  landConsolidation,
  projectIntegration,
}) => {
  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[12rem] text-left text-[1.25rem] text-text-primary font-text-small-normal ${className}`}
    >
      <img
        className="w-[3rem] h-[3rem] relative overflow-hidden shrink-0"
        alt=""
        src="/icon--relume1.svg"
      />
      <b className="self-stretch relative leading-[140%] mq450:text-[1rem] mq450:leading-[1.375rem]">
        {subheadingOne}
      </b>
      <div className="self-stretch relative text-[1rem] leading-[150%]">
        <p className="m-0">{siteIdentification}</p>
        <p className="m-0">{dealNegotiation}</p>
        <p className="m-0">{landConsolidation}</p>
        <p className="m-0">{projectIntegration}</p>
      </div>
    </div>
  );
};

ListItem4.propTypes = {
  className: PropTypes.string,
  subheadingOne: PropTypes.string,
  siteIdentification: PropTypes.string,
  dealNegotiation: PropTypes.string,
  landConsolidation: PropTypes.string,
  projectIntegration: PropTypes.string,
};

export default ListItem4;
