import PropTypes from "prop-types";

const Card5 = ({ className = "", placeholderImage }) => {
  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[19rem] max-w-full text-left text-[1.25rem] text-text-primary font-text-small-normal ${className}`}
    >
      <img
        className="w-[5rem] h-[5rem] relative object-contain"
        alt=""
        src={placeholderImage}
      />
      <div className="self-stretch flex flex-col items-center justify-start gap-[1rem]">
        <div className="self-stretch flex flex-col items-center justify-start">
          <div className="self-stretch relative leading-[150%] font-semibold mq450:text-[1rem] mq450:leading-[1.5rem]">
            Full name
          </div>
          <div className="self-stretch relative text-[1.125rem] leading-[150%]">
            Job title
          </div>
        </div>
        <div className="self-stretch relative text-[1rem] leading-[150%]">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
          varius enim in eros elementum tristique.
        </div>
      </div>
      <div className="flex flex-row items-start justify-start gap-[0.875rem]">
        <img
          className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
          alt=""
          src="/icon--linkedin.svg"
        />
        <img
          className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
          alt=""
          src="/icon--x.svg"
        />
        <img
          className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
          alt=""
          src="/icon--dribble.svg"
        />
      </div>
    </div>
  );
};

Card5.propTypes = {
  className: PropTypes.string,
  placeholderImage: PropTypes.string,
};

export default Card5;
