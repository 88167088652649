import { useMemo } from "react";
import PropTypes from "prop-types";

const Card7 = ({
  className = "",
  category,
  heading,
  postSummaryPair,
  fullName,
  jan2022,
  readTimePair,
  propFlex,
  propMinWidth,
}) => {
  const cardStyle = useMemo(() => {
    return {
      flex: propFlex,
      minWidth: propMinWidth,
    };
  }, [propFlex, propMinWidth]);

  return (
    <div
      className={`flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[19.5rem] max-w-full text-left text-[0.875rem] text-text-primary font-text-small-normal ${className}`}
      style={cardStyle}
    >
      <img
        className="self-stretch h-[18.75rem] relative max-w-full overflow-hidden shrink-0 object-cover"
        alt=""
        src="/placeholder-image@2x.png"
      />
      <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
        <div className="self-stretch relative leading-[150%] font-semibold">
          {category}
        </div>
        <b className="self-stretch relative text-[1.5rem] leading-[140%] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
          {heading}
        </b>
        <div className="self-stretch relative text-[1rem] leading-[150%]">
          {postSummaryPair}
        </div>
      </div>
      <div className="self-stretch flex flex-row items-center justify-start gap-[1rem] max-w-full mq450:flex-wrap">
        <img
          className="h-[3rem] w-[3rem] relative object-cover"
          alt=""
          src="/placeholder-image@2x.png"
        />
        <div className="flex-1 flex flex-col items-start justify-start min-w-[14.313rem] max-w-full">
          <div className="self-stretch relative leading-[150%] font-semibold">
            {fullName}
          </div>
          <div className="self-stretch flex flex-row items-center justify-start gap-[0.5rem] mq450:flex-wrap">
            <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
              {jan2022}
            </div>
            <div className="relative text-[1.125rem] leading-[150%] inline-block min-w-[0.375rem] mq450:w-full mq450:h-[0.375rem]">
              •
            </div>
            <div className="relative leading-[150%] inline-block min-w-[4.75rem]">
              {readTimePair}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Card7.propTypes = {
  className: PropTypes.string,
  category: PropTypes.string,
  heading: PropTypes.string,
  postSummaryPair: PropTypes.string,
  fullName: PropTypes.string,
  jan2022: PropTypes.string,
  readTimePair: PropTypes.string,

  /** Style props */
  propFlex: PropTypes.any,
  propMinWidth: PropTypes.any,
};

export default Card7;
