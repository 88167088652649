import PropTypes from "prop-types";
import {
  FaUser,
  FaHistory,
  FaLandmark,
  FaIndustry,
  FaBuilding,
  FaMapMarkedAlt,
  FaPowerOff,
  FaGem,
} from "react-icons/fa"; // Example icons, you can choose appropriate ones

const getIcon = (pageOne) => {
  switch (pageOne) {
    case "Leadership":
      return <FaUser />;
    case "Past and Future":
      return <FaHistory />;
    case "Strategic Land Aggregation":
      return <FaLandmark />;
    case "Industrial Construction":
      return <FaIndustry />;
    case "Industrial Parks":
      return <FaBuilding />;
    case "VR Realty Advisory":
      return <FaMapMarkedAlt />;
    case "SSR Power":
      return <FaPowerOff />;
    case "Mines":
      return <FaGem />;
    default:
      return <FaUser />; // Default icon, change it as needed
  }
};

const MenuItem = ({ className = "", pageOne }) => {
  return (
    <div
      className={`self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] box-border gap-[0.75rem] max-w-full text-left text-[1rem] text-link-primary font-text-small-normal mq450:flex-wrap ${className}`}
    >
      <div className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0">
        {getIcon(pageOne)}
      </div>
      <div className="flex-1 flex flex-col items-start justify-start min-w-[13.813rem] max-w-full">
        <div className="self-stretch relative leading-[150%] font-semibold">
          {pageOne}
        </div>
      </div>
    </div>
  );
};

MenuItem.propTypes = {
  className: PropTypes.string,
  pageOne: PropTypes.string,
};

export default MenuItem;
