import React, { useState } from "react";
import { Link } from "react-router-dom";
import Content from "../components/content";
import MenuItem from "../components/menuitem";
import BlogItem from "../components/blogitem";
import Column from "../components/column";
import Card4 from "../components/card4";
import Card5 from "../components/card5";
import Content1 from "../components/content1";
import Container from "../components/container";
import Contact from "../components/contact";
import Footer from "../components/footer";
import PropTypes from "prop-types";
import ColumnHome1 from "../components/columnhome1";
import ColumnHome2 from "../components/columnhome2";
import ColumnHome3 from "../components/columnhome3";

const CompanyPage = ({ className = "" }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div
      className={`absolute top-[0rem] left-[0rem] bg-background-color-primary overflow-hidden flex flex-col items-start justify-start max-w-full text-left text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
        {isMenuVisible && (
          <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
            <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
              <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Komal Group
                  </div>
                  <Link to="/aboutuspage#leadership" className="no-underline text-inherit">
                    <MenuItem pageOne="Leadership" />
                  </Link>
                  <Link to="/aboutuspage#timeline" className="no-underline text-inherit">
                    <MenuItem pageOne="Past and Future" />
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit">
                    <MenuItem pageOne="Mines" />
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit">
                    <MenuItem pageOne="SSR Power" />
                  </Link>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    Offerings
                  </div>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Parks" />
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit">
                    <MenuItem pageOne="VR Realty Advisory" />
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Industrial Construction" />
                  </Link>
                  <Link to="/landaggregationpage" className="no-underline text-inherit">
                    <MenuItem pageOne="Strategic Land Aggregations" />
                  </Link>
                </div>
              </div>
              <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
                <div className="self-stretch relative text-[0.875rem] leading-[150%] font-semibold">
                  Downloads
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
                  <Link to="/developmentmaps" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/govt-maps.png"
                      articleTitle="Development Maps"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/gr.png"
                      articleTitle="Government Resolutions"
                      readMore="Learn More"
                    />
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit">
                    <BlogItem
                      placeholderImage="/brochure.png"
                      articleTitle="Corporate Brochure"
                      readMore="Download"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* Hero Section */}
      <div className="relative self-stretch overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full text-[3.5rem] text-background-color-primary">
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src="/teamkomal.png"
          alt="Team Komal"
        />
        <div className="relative z-10 self-stretch flex flex-row flex-wrap items-start justify-start gap-[5rem] max-w-full bg-black bg-opacity-50 p-[2rem]">
          <div className="flex-1 flex flex-col items-start justify-start min-w-[25rem] max-w-full mq750:min-w-full">
            <b className="self-stretch relative leading-[120%] mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]">
              Excellence in Real Estate
            </b>
            <div className="self-stretch relative text-[1.125rem] leading-[150%]">
              Komal Properties is a leading industrial real estate company in Pune
              district, specializing in the Chakan and Talegaon areas. With a
              strong commitment to excellence, we offer a range of services
              including land aggregation, industrial and warehouse construction,
              strategic consulting and advisory, and industrial and residential
              plotting projects. Our experienced team and impressive track record
              make us the preferred choice for all your industrial real estate
              needs.
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[4rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[1rem] min-w-[25.375rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1rem]">
              <div className="self-stretch relative leading-[150%] font-semibold">
                We are Komal
              </div>
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem] text-[3rem]">
                <b className="self-stretch relative leading-[120%] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                  Building a Future of Industrial Excellence and Innovation
                </b>
                <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                  At Komal Properties, we are committed to providing innovative
                  and sustainable industrial real estate solutions. Our vision
                  is to create a thriving ecosystem that supports businesses and
                  drives economic growth. With a dedicated team and a strong
                  focus on quality, integrity, and customer satisfaction, we
                  strive to exceed expectations and deliver exceptional value to
                  our clients.
                </div>
              </div>
            </div>
            <div className="h-[4rem] flex flex-row items-center justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1.5rem]">
              <div className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] border-[1px] border-solid border-link-primary">
                <div className="relative leading-[150%] inline-block min-w-[5.688rem] whitespace-nowrap">
                  Learn More
                </div>
              </div>
              <div className="flex flex-row items-center justify-center gap-[0.5rem]">
                <div className="relative leading-[150%] inline-block min-w-[5.563rem]">
                  Contact Us
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                  alt="Chevron Right"
                  src="/icon--chevronright.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[39.875rem] w-[39rem] relative max-w-full">
            <img
              className="absolute h-full top-[0rem] right-[0rem] bottom-[0rem] max-h-full w-[27.625rem] object-cover"
              alt="Industrial Park"
              src="/industrialparkimage.png"
            />
            <img
              className="absolute top-[4rem] left-[0rem] w-[19rem] h-[19rem] object-cover"
              alt="Komal Properties"
              src="/komalimage.png"
            />
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full text-[2rem]">
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[3rem] max-w-full">
          <ColumnHome1
            heading="Mission"
            text="At Komal Properties, we are driven by our mission to provide exceptional industrial real estate solutions, guided by our vision to be the preferred choice for businesses in Pune district. Our values of integrity, innovation, and customer-centricity are at the core of everything we do."
          />
          <ColumnHome2
            heading="Vision"
            text="Our leadership team brings together years of experience and expertise in the industrial real estate industry. With their guidance, we are able to deliver outstanding results for our clients."
          />
          <ColumnHome3
            heading="Values"
            text="Since our inception in 2002, we have achieved significant milestones and established ourselves as a trusted name in the industrial real estate market. Explore our timeline to learn more about our journey."
          />
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <div className="w-[48rem] flex flex-col items-start justify-start gap-[1rem] max-w-full">
          <div className="relative leading-[150%] font-semibold inline-block min-w-[5.5rem]">
            Excellence
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-[3rem]">
            <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
              Leadership
            </b>
            <div className="self-stretch relative text-[1.125rem] leading-[150%]">
              Team of industry veterans
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[6rem] max-w-full text-[1.25rem]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4rem] max-w-full">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[4rem] max-w-full">
              <Card4
                name1="Rajendra Gaikwad"
                jobTitle="Managing Director and Chairman"
                text="Rajendra Gaikwad is a visionary leader with a proven track record in the real estate industry."
              />
              <Card4
                name1="Shubham Gaikwad"
                jobTitle="Director and CEO"
                text="Shubham Gaikwad leads our projects and development, ensuring innovative solutions for our clients."
              />
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full">
        <div className="w-[48rem] flex flex-col items-start justify-start gap-[1rem] max-w-full">
          <div className="relative leading-[150%] font-semibold inline-block min-w-[3.813rem]">
            Dedicated to your success
          </div>
          <div className="self-stretch flex flex-col items-center justify-start gap-[1.5rem] text-[3rem]">
            <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
              Our team
            </b>
            <div className="self-stretch relative text-[1.125rem] leading-[150%]">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. `}</div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[6rem] max-w-full text-[1.25rem]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[4rem] max-w-full">
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[3rem] max-w-full">
              <Card5 placeholderImage="/placeholder-image@2x.png" />
              <Card5 placeholderImage="/placeholder-image@2x.png" />
              <Card5 placeholderImage="/placeholder-image@2x.png" />
            </div>
            <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[3rem] max-w-full">
              <Card5 placeholderImage="/placeholder-image@2x.png" />
              <Card5 placeholderImage="/placeholder-image@2x.png" />
              <Card5 placeholderImage="/placeholder-image@2x.png" />
            </div>
          </div>
          <div className="w-[48rem] flex flex-col items-start justify-start gap-[1.5rem] max-w-full text-[2rem]">
            <div className="self-stretch flex flex-col items-center justify-start gap-[1rem]">
              <b className="self-stretch relative leading-[2.625rem] mq750:text-[1.625rem] mq750:leading-[2.063rem] mq450:text-[1.188rem] mq450:leading-[1.563rem]">
                We’re hiring!
              </b>
              <div className="self-stretch relative text-[1.125rem] leading-[150%]">{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. `}</div>
            </div>
            <div className="flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] text-[1rem] border-[1px] border-solid border-link-primary">
              <div className="relative leading-[150%] inline-block min-w-[7.625rem]">
                Open positions
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-center py-[7rem] px-[4rem] box-border max-w-full text-center text-[1.5rem]">
        <div className="self-stretch flex flex-col items-center justify-start gap-[4rem] max-w-full">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-start [row-gap:20px]">
            <Content1 date="2002" />
            <Content1 date="2007" />
            <Content1 date="2012" />
            <Content1 date="2018" />
            <Content1 date="2023" />
          </div>
          <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[5rem] max-w-full text-left text-[2.5rem]">
            <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[1rem] min-w-[25rem] max-w-full mq750:min-w-full">
              <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
                <div className="self-stretch flex flex-col items-start justify-start gap-[1rem]">
                  <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
                    2002
                  </b>
                  <b className="self-stretch relative text-[2rem] leading-[130%] mq750:text-[1.625rem] mq750:leading-[2.063rem] mq450:text-[1.188rem] mq450:leading-[1.563rem]">
                    Experience our journey of innovation and excellence.
                  </b>
                </div>
                <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                  Discover the milestones and achievements that define our
                  company's history.
                </div>
              </div>
              <div className="h-[4rem] flex flex-row items-center justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1.5rem] text-[1rem]">
                <div className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] border-[1px] border-solid border-link-primary">
                  <div className="relative leading-[150%] inline-block min-w-[3.813rem]">
                    Explore
                  </div>
                </div>
                <div className="flex flex-row items-center justify-center gap-[0.5rem]">
                  <div className="relative leading-[150%] inline-block min-w-[5.688rem] whitespace-nowrap">
                    Learn More
                  </div>
                  <img
                    className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                    alt="Chevron Right"
                    src="/icon--chevronright.svg"
                  />
                </div>
              </div>
            </div>
            <img
              className="h-[40rem] flex-1 relative max-w-full overflow-hidden object-cover min-w-[25rem] mq750:min-w-full"
              alt=""
              src="/placeholder-image@2x.png"
            />
          </div>
        </div>
      </div>
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border gap-[5rem] max-w-full text-[3rem]">
        <div className="w-[35rem] flex flex-col items-start justify-start gap-[1.5rem] max-w-full">
          <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
            Client Testimonials
          </b>
          <div className="self-stretch relative text-[1.125rem] leading-[150%]">
            Read what our satisfied clients and partners have to say
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start gap-[3rem] max-w-full text-[1.25rem]">
          <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[4rem] max-w-full">
            <Container
              quote="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor, felis nec lacinia mollis, est turpis gravida enim, quis porttitor leo risus non mi."
              clientAvatar="John Doe"
              avatarPicture="CEO, ABC Company"
            />
            <Container
              quote="Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed auctor, felis nec lacinia mollis, est turpis gravida enim, quis porttitor leo risus non mi."
              clientAvatar="Jane Smith"
              avatarPicture="CFO, XYZ Corporation"
            />
          </div>
          <div className="self-stretch flex flex-row items-center justify-between gap-[1.25rem] mq450:flex-wrap mq450:justify-center">
            <div className="flex flex-row items-start justify-start gap-[0.5rem]">
              <div className="h-[0.5rem] w-[0.5rem] relative rounded-[50%] bg-link-primary" />
              <div className="h-[0.5rem] w-[0.5rem] relative rounded-[50%] bg-color-neutral-neutral-lighter" />
              <div className="h-[0.5rem] w-[0.5rem] relative rounded-[50%] bg-color-neutral-neutral-lighter" />
              <div className="h-[0.5rem] w-[0.5rem] relative rounded-[50%] bg-color-neutral-neutral-lighter" />
              <div className="h-[0.5rem] w-[0.5rem] relative rounded-[50%] bg-color-neutral-neutral-lighter" />
            </div>
            <div className="flex flex-row items-start justify-start gap-[0.937rem]">
              <div className="h-[3rem] w-[3rem] rounded-31xl box-border flex flex-row items-center justify-center py-[0.75rem] px-[0.687rem] border-[1px] border-solid border-border-primary">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                  alt="icon"
                  src="/icon.svg"
                />
              </div>
              <div className="h-[3rem] w-[3rem] rounded-31xl box-border flex flex-row items-center justify-center py-[0.75rem] px-[0.687rem] border-[1px] border-solid border-border-primary">
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                  alt="icon"
                  src="/icon1.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/line-1.svg"
        emailInput="/line-2.svg"
      />
      <Footer />
    </div>
  );
};

CompanyPage.propTypes = {
  className: PropTypes.string,
};

export default CompanyPage;
