import PropTypes from "prop-types";

const Content1 = ({ className = "", date }) => {
  return (
    <div
      className={`flex-1 flex flex-col items-center justify-center py-[0.5rem] px-[0rem] box-border gap-[0.5rem] min-w-[16.063rem] max-w-[16.375rem] text-center text-[1.5rem] text-text-primary font-text-small-normal ${className}`}
    >
      <b className="self-stretch relative leading-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
        {date}
      </b>
      <div className="self-stretch flex flex-row items-center justify-center gap-[0.5rem] mq450:flex-wrap">
        <div className="h-[0.188rem] flex-1 relative bg-border-primary min-w-[4.688rem]" />
        <div className="h-[0.938rem] w-[0.938rem] relative rounded-[50%] bg-link-primary mq450:w-full mq450:h-[0.938rem]" />
        <div className="h-[0.188rem] flex-1 relative bg-text-primary min-w-[4.688rem]" />
      </div>
    </div>
  );
};

Content1.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
};

export default Content1;
