import PropTypes from "prop-types";

const Card = ({ className = "", placeholderImage, heading }) => {
  return (
    <div
      className={`flex-1 box-border flex flex-col items-start justify-start min-w-[25.75rem] max-w-full shrink-0 text-left text-[1.5rem] text-text-primary font-text-small-normal border-[1px] border-solid border-border-primary mq750:min-w-full ${className}`}
    >
      <img
        className="self-stretch h-[22.25rem] relative max-w-full overflow-hidden shrink-0 object-cover"
        alt=""
        src={placeholderImage}
      />
      <div className="self-stretch flex flex-col items-start justify-start p-[1.5rem]">
        <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem]">
          <b className="self-stretch relative leading-[2.125rem] mq450:text-[1.188rem] mq450:leading-[1.688rem]">
            {heading}
          </b>
          <div className="self-stretch relative text-[1rem] leading-[150%]">
            Lorem ipsum dolor sit amet
          </div>
        </div>
        <div className="self-stretch flex flex-row items-start justify-start pt-[1rem] px-[0rem] pb-[0rem] gap-[0.5rem] text-[0.875rem] mq450:flex-wrap">
          <div className="bg-background-color-secondary flex flex-row items-start justify-start py-[0.25rem] px-[0.5rem]">
            <div className="relative leading-[150%] font-semibold inline-block min-w-[4.313rem]">
              Industrial
            </div>
          </div>
          <div className="bg-background-color-secondary flex flex-row items-start justify-start py-[0.25rem] px-[0.5rem] whitespace-nowrap">
            <div className="relative leading-[150%] font-semibold inline-block min-w-[5.063rem]">
              Real Estate
            </div>
          </div>
          <div className="bg-background-color-secondary flex flex-row items-start justify-start py-[0.25rem] px-[0.437rem]">
            <div className="relative leading-[150%] font-semibold inline-block min-w-[2.438rem]">
              Pune
            </div>
          </div>
        </div>
        <div className="self-stretch flex flex-col items-start justify-start pt-[1.5rem] px-[0rem] pb-[0rem] text-[1rem]">
          <div className="flex flex-row items-center justify-center gap-[0.5rem]">
            <div className="relative leading-[150%] inline-block min-w-[6.25rem]">
              View Project
            </div>
            <img
              className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
              alt=""
              src="/icon--chevronright.svg"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

Card.propTypes = {
  className: PropTypes.string,
  placeholderImage: PropTypes.string,
  heading: PropTypes.string,
};

export default Card;
