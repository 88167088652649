import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Content from '../components/content';
import MenuItem from '../components/menuitem';
import BlogItem from '../components/blogitem';
import ListItem4 from '../components/listitem4';
import SectionTitle from '../components/sectiontitle';
import ListItem from '../components/listitem';
import Layout4 from '../components/layout4';
import Contact from '../components/contact';
import Footer from '../components/footer';
import PropTypes from 'prop-types';
import Layout4Aggregation from '../components/layout4aggregation';
import Layout4Construction from '../components/layout4construction';
import Layout4plotting from '../components/layout4plotting';

const landaggregationbg = process.env.PUBLIC_URL + '/industrialparks.png';
const komallandaggbg2 = process.env.PUBLIC_URL + '/industrialparkbg.png';
const greenkomalgeneralbg = process.env.PUBLIC_URL + '/greenkomalgeneralbg.png';

const PlottingProjectsPage = ({ className = "" }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [isMobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!isMobileMenuVisible);
  };

  return (
    <div
      className={`absolute top-[0rem] left-[0rem] bg-background-color-primary overflow-hidden flex flex-col items-start justify-start max-w-full text-left text-[1rem] text-text-primary font-text-small-normal ${className}`}
    >
      <div className="self-stretch bg-background-color-primary box-border overflow-hidden flex flex-col items-center justify-start max-w-full border-b-[1px] border-solid border-border-primary">
        <div className="self-stretch h-[4.5rem] box-border flex flex-row items-center justify-between py-[0rem] px-[4rem] top-[0] z-[99] sticky max-w-full gap-[1.25rem] border-b-[1px] border-solid border-border-primary">
          <div className="flex flex-row items-center justify-start py-[1.25rem] px-[0rem] box-border gap-[2.5rem] max-w-full">
            <Link to="/">
              <img
                className="self-stretch w-[3.938rem] relative max-h-full overflow-hidden shrink-0 min-h-[1.688rem]"
                alt="Company Logo"
                src="/komalpropertiesnewnewlogo.png"
              />
            </Link>
            <div className="overflow-hidden flex flex-row items-start justify-between max-w-full gap-[2.5rem] mq750:hidden">
              <Link to="/projectspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.063rem]">
                  Projects
                </div>
              </Link>
              <a href="#solutions" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.688rem]">
                  Solutions
                </div>
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit">
                <div className="relative leading-[150%] inline-block min-w-[4.875rem]">
                  Company
                </div>
              </Link>
              <div
                className="flex flex-row items-center justify-center gap-[0.25rem] cursor-pointer"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
            </div>
          </div>
          <div className="h-[2.5rem] flex flex-row items-center justify-center gap-[1rem] text-text-primary mq750:hidden">
            <Link to="/contactuspage" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[4rem]">
                  Contact
                </div>
              </div>
            </Link>
            <Link to="/bookvisit" className="no-underline text-inherit">
              <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                  Book a Visit
                </div>
              </div>
            </Link>
          </div>
          <div className="mq750:flex hidden" onClick={toggleMobileMenu}>
            <img
              className="h-[2rem] w-[2rem] relative overflow-hidden shrink-0"
              alt="Toggle Mobile Menu"
              src="/icons8-menu-50.png"
            />
          </div>
        </div>

        {/* Conditional Menu Section */}
        {isMenuVisible && (
          <div className="self-stretch bg-background-color-primary box-border flex flex-col items-center justify-start max-w-full text-left text-link-primary border-b-[1px] border-solid border-border-primary mq750:hidden">
            <div className="self-stretch overflow-hidden flex flex-row items-start justify-start [row-gap:20px] max-w-full text-[0.875rem] text-text-primary mq1225:flex-wrap">
              <div className="flex-1 flex flex-row items-start justify-start py-[2rem] pr-[2rem] pl-[4rem] box-border gap-[2rem] min-w-[35.75rem] max-w-full mq750:flex-wrap mq750:min-w-full">
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    About Us
                  </div>
                  <Link
                    to="/aboutuspage#leadership"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="Leadership" />
                  </Link>
                  <Link
                    to="/aboutuspage#timeline"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="History" />
                  </Link>
                  <Link
                    to="/landaggregationpage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="Strategic Land Aggregation" />
                  </Link>
                  <Link
                    to="/industrialconstructionpage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="Industrial Construction" />
                  </Link>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[1rem] min-w-[15.25rem] max-w-full">
                  <div className="self-stretch relative leading-[150%] font-semibold">
                    More Information
                  </div>
                  <Link
                    to="/plottingprojectspage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="Industrial Parks" />
                  </Link>
                  <Link
                    to="/vrrealtypage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="VR Realty Advisory" />
                  </Link>
                  <Link
                    to="/ssrpowerpage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="SSR Power" />
                  </Link>
                  <Link
                    to="/minespage"
                    className="no-underline text-inherit"
                  >
                    <MenuItem pageOne="Mines" />
                  </Link>
                </div>
              </div>
              <div className="w-[35rem] bg-background-color-secondary flex flex-col items-start justify-start py-[2rem] pr-[6rem] pl-[2rem] box-border gap-[1rem] min-w-[35rem] max-w-full text-[1rem] mq750:min-w-full mq1225:flex-1">
                <div className="self-stretch relative leading-[150%] font-semibold">
                  Featured
                </div>
                <div className="self-stretch flex flex-col items-start justify-start gap-[0.5rem] text-link-primary">
                  <BlogItem
                    placeholderImage="/industrial-park-prject.jpg"
                    articleTitle="Recent Project"
                    readMore="See more"
                  />
                  <BlogItem
                    placeholderImage="/city-sunset.jpg"
                    articleTitle="Recent Blog"
                    readMore="Read more"
                    propFlexDirection="row"
                    propFlex="unset"
                  />
                </div>
                <div className="flex flex-row items-center justify-center py-[0.25rem] px-[0rem] gap-[0.5rem]">
                  <div className="relative leading-[150%] inline-block min-w-[7.125rem]">
                    See all articles
                  </div>
                  <img
                    className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0"
                    alt="Chevron Right"
                    src="/icon--chevronright.svg"
                  />
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Mobile Menu Section */}
        {isMobileMenuVisible && (
          <div className="mq750:flex flex-col items-center justify-start w-full bg-background-color-primary p-4 border-b-[1px] border-solid border-border-primary">
            <div className="self-stretch ml-[2px]">
              <Link to="/projectspage" className="no-underline text-inherit mb-4 block">
                Projects
              </Link>
              <a href="#solutions" className="no-underline text-inherit mb-4 block">
                Solutions
              </a>
              <Link to="/aboutuspage" className="no-underline text-inherit mb-4 block">
                Company
              </Link>
              <div
                className="flex flex-col items-start justify-center gap-[0.25rem] cursor-pointer mb-4 block"
                onClick={toggleMenu}
              >
                <div className="relative leading-[150%] inline-block min-w-[2.625rem]">
                  More
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt="Toggle Menu"
                  src="/chevron-down.svg"
                />
              </div>
              {isMenuVisible && (
                <div className="ml-4">
                  <Link to="/landaggregationpage" className="no-underline text-inherit mb-2 block">
                    Strategic Land Aggregation
                  </Link>
                  <Link to="/industrialconstructionpage" className="no-underline text-inherit mb-2 block">
                    Industrial Construction
                  </Link>
                  <Link to="/plottingprojectspage" className="no-underline text-inherit mb-2 block">
                    Industrial Parks
                  </Link>
                  <Link to="/vrrealtypage" className="no-underline text-inherit mb-2 block">
                    VR Realty Advisory
                  </Link>
                  <Link to="/ssrpowerpage" className="no-underline text-inherit mb-2 block">
                    SSR Power
                  </Link>
                  <Link to="/minespage" className="no-underline text-inherit mb-2 block">
                    Mines
                  </Link>
                  <Link to="/developmentmaps" className="no-underline text-inherit mb-2 block">
                    Development Maps
                  </Link>
                  <Link to="/governmentreports" className="no-underline text-inherit mb-2 block">
                    Government Resolutions
                  </Link>
                  <Link to="/corporatebrochure" className="no-underline text-inherit mb-2 block">
                    Corporate Brochure
                  </Link>
                </div>
              )}
              <Link to="/contactuspage" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] border-[1px] border-solid" style={{ borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[4rem]">
                    Contact
                  </div>
                </div>
              </Link>
              <Link to="/bookvisit" className="no-underline text-inherit mb-4 block">
                <div className="h-[2.625rem] box-border flex flex-row items-center justify-center py-[0.5rem] px-[1.25rem] whitespace-nowrap text-background-color-primary border-[1px] border-solid" style={{ backgroundColor: "#015730", borderColor: "#015730" }}>
                  <div className="relative leading-[150%] inline-block min-w-[5.875rem]">
                    Book a Visit
                  </div>
                </div>
              </Link>
            </div>
          </div>
        )}
      </div>

      {/* Hero Section */}
      <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border bg-cover bg-no-repeat bg-[top] max-w-full text-[3.5rem] text-background-color-primary" style={{ backgroundImage: `url(${landaggregationbg})` }}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-start gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start min-w-[25rem] max-w-full mq750:min-w-full">
            <b className="self-stretch relative leading-[120%] mq750:text-[2.813rem] mq750:leading-[3.375rem] mq450:text-[2.125rem] mq450:leading-[2.5rem]">
              Strategic Land Aggregation
            </b>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start min-w-[25rem] max-w-full text-[1.125rem] mq750:min-w-full">
            <div className="self-stretch relative leading-[150%]">
              Discover the power of strategic land aggregation with Komal
              Properties. Our expertise and experience in the Pune district,
              especially in Chakan and Talegaon, allows us to identify and
              acquire prime industrial land for your business needs. With our
              comprehensive knowledge of the local market, we can help you
              secure the perfect location for your industrial real estate
              projects.
            </div>
          </div>
        </div>
      </div>
      <div className="self-stretch bg-background-color-primary overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border max-w-full text-[2.5rem]">
        <div className="self-stretch flex flex-row flex-wrap items-center justify-start gap-[5rem] max-w-full">
          <div className="flex-1 flex flex-col items-start justify-start py-[1.25rem] px-[0rem] box-border gap-[2rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
              <b className="self-stretch relative leading-[120%] mq750:text-[2rem] mq750:leading-[2.375rem] mq450:text-[1.5rem] mq450:leading-[1.813rem]">
                Providing Strategic Land Aggregation Solutions for Industrial
                Real Estate Projects
              </b>
              <div className="self-stretch relative text-[1.125rem] leading-[150%]">
                Our Strategic Land Aggregation service offers a comprehensive
                approach to acquiring and consolidating land for industrial real
                estate projects. We specialize in identifying prime locations,
                negotiating deals, and streamlining the entire process.
              </div>
            </div>
            <div className="self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-[1.25rem] mq750:flex-wrap">
              <ListItem4
                subheadingOne="Process"
                siteIdentification="1. Site Identification"
                dealNegotiation="2. Deal Negotiation"
                landConsolidation="3. Land Consolidation"
                projectIntegration="4. Project Integration"
              />
              <ListItem4
                subheadingOne="Benefits"
                siteIdentification="1. Cost Efficiency"
                dealNegotiation="2. Time Savings"
                landConsolidation="3. Enhanced Project Feasibility"
                projectIntegration="4. Competitive Advantage"
              />
            </div>
          </div>
          <img
            className="h-[40rem] flex-1 relative max-w-full overflow-hidden object-cover min-w-[25rem] mq750:min-w-full"
            alt=""
            src={komallandaggbg2}
          />
        </div>
      </div>
      <div className="self-stretch overflow-hidden flex flex-col items-start justify-start py-[7rem] px-[4rem] box-border bg-cover bg-no-repeat bg-[top] max-w-full text-background-color-primary" style={{ backgroundImage: `url(${greenkomalgeneralbg})` }}>
        <div className="self-stretch flex flex-row flex-wrap items-start justify-center gap-[5rem] max-w-full">
          <div className="flex-1 overflow-hidden flex flex-col items-start justify-start gap-[1rem] min-w-[25rem] max-w-full mq750:min-w-full">
            <div className="self-stretch relative leading-[150%] font-semibold">
              Expertise
            </div>
            <b className="self-stretch h-[14.5rem] relative text-[3rem] tracking-[-0.12px] leading-[120%] inline-block mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
              Strategic Land Aggregation Services for Industrial Real Estate
            </b>
          </div>
          <div className="flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[25rem] max-w-full text-[1.125rem] mq750:min-w-full">
            <div className="self-stretch flex flex-col items-start justify-start gap-[2rem]">
              <div className="self-stretch relative leading-[150%]">
                Our strategic land aggregation services provide comprehensive
                solutions for industrial real estate projects. We specialize in
                identifying and acquiring prime land parcels that are ideal for
                industrial development.
              </div>
              <div className="self-stretch flex flex-row items-start justify-start py-[0.5rem] px-[0rem] gap-[1.5rem] text-[3rem] mq750:flex-wrap">
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                  <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                    50%
                  </b>
                  <div className="self-stretch relative text-[1rem] leading-[150%]">
                    Increased Efficiency in Land Acquisition and Development
                  </div>
                </div>
                <div className="flex-1 flex flex-col items-start justify-start gap-[0.5rem] min-w-[12rem]">
                  <b className="self-stretch relative leading-[3.625rem] mq750:text-[2.375rem] mq750:leading-[2.875rem] mq450:text-[1.813rem] mq450:leading-[2.188rem]">
                    50%
                  </b>
                  <div className="self-stretch relative text-[1rem] leading-[150%]">
                    Reduced Costs and Enhanced Profitability for Industrial
                    Projects
                  </div>
                </div>
              </div>
            </div>
            <div className="h-[4rem] flex flex-row items-center justify-start pt-[1rem] px-[0rem] pb-[0rem] box-border gap-[1.5rem] text-[1rem]">
              <div className="h-[3.125rem] box-border flex flex-row items-center justify-center py-[0.75rem] px-[1.5rem] border-[1px] border-solid border-background-color-primary">
                <div className="relative leading-[150%] inline-block min-w-[5.688rem] whitespace-nowrap">
                  Learn More
                </div>
              </div>
              <div className="flex flex-row items-center justify-center gap-[0.5rem]">
                <div className="relative leading-[150%] inline-block min-w-[5.563rem]">
                  Contact Us
                </div>
                <img
                  className="h-[1.5rem] w-[1.5rem] relative overflow-hidden shrink-0 min-h-[1.5rem]"
                  alt=""
                  src="/icon--chevronright1.svg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Layout4plotting
        subheading="Tagline"
        heading="Medium length section heading goes here"
        loremIpsumDolorSitAmetCon="Explore our portfolio of successful land aggregation projects."
        featureOne="Feature one"
        heading1="Successful Land Aggregation Projects"
        text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget ex ac tellus gravida lacinia."
        featureTwo="Feature two"
        featureThree="Feature three"
        featureFour="Feature four"
      />
      <Contact
        loremIpsumDolorSitAmetCon="Contact us for all your land aggregation needs."
        hellorelumeio="info@komalproperties.com"
        sampleStSydneyNSW2000AU="123 Main St, Pune, Maharashtra 411001, India"
        typeYourMessage="Enter your message..."
        namePlaceholder="/line-1.svg"
        emailInput="/line-2.svg"
      />
      <Footer />
    </div>
  );
};

PlottingProjectsPage.propTypes = {
  className: PropTypes.string,
};

export default PlottingProjectsPage;
