import { useEffect } from "react";
import { Routes, Route, useNavigationType, useLocation } from "react-router-dom";
import HomePage from "./pages/homepage";
import CompanyPage from "./pages/aboutuspage";
import ContactUsPage from "./pages/contactuspage";
import BlogPage from "./pages/blogpage";
import SSRPowerPage from "./pages/ssrpowerpage";
import MinesPage from "./pages/minespage";
import LandAggregationPage from "./pages/landaggregationpage";
import IndustrialConstructionPage from "./pages/industrialconstructionpage";
import VRRealtyPage from "./pages/vrrealtypage";
import ProjectsPage from "./pages/projectspage";
import PlottingPage from "./pages/plottingprojectspage";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "Home";
        metaDescription = "Welcome to Komal Properties";
        break;
      case "/aboutuspage":
        title = "About Us";
        metaDescription = "Learn more about Komal Properties";
        break;
      case "/contactuspage":
        title = "Contact Us";
        metaDescription = "Get in touch with Komal Properties";
        break;
      case "/blogpage":
        title = "Blog";
        metaDescription = "Read our latest blog posts";
        break;
      case "/ssrpowerpage":
        title = "SSR Power";
        metaDescription = "Learn more about SSR Power";
        break;
      case "/minespage":
        title = "Mines";
        metaDescription = "Discover our mining operations";
        break;
      case "/landaggregationpage":
        title = "Land Aggregation";
        metaDescription = "Learn more about our land aggregation services";
        break;
      case "/industrialconstructionpage":
        title = "Industrial Construction";
        metaDescription = "Learn more about our industrial construction services";
        break;
      case "/plottingprojectspage":
        title = "Plotting";
        metaDescription = "Learn more about our plotting services";
        break;
      case "/vrrealtypage":
        title = "VR Realty Advisory";
        metaDescription = "Learn more about VR Realty Advisory";
        break;
      case "/projectspage":
        title = "Projects";
        metaDescription = "Learn more about our projects";
        break;
      default:
        title = "Komal Properties";
        metaDescription = "Komal Properties website";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector('head > meta[name="description"]');
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/aboutuspage" element={<CompanyPage />} />
      <Route path="/contactuspage" element={<ContactUsPage />} />
      <Route path="/blogpage" element={<BlogPage />} /> 
      <Route path="/ssrpowerpage" element={<SSRPowerPage />} />
      <Route path="/minespage" element={<MinesPage />} />
      <Route path="/landaggregationpage" element={<LandAggregationPage />} />
      <Route path="/industrialconstructionpage" element={<IndustrialConstructionPage />} />
      <Route path="/plottingprojectspage" element={<PlottingPage />} />
      <Route path="/vrrealtypage" element={<VRRealtyPage />} />
      <Route path="/projectspage" element={<ProjectsPage />} />
      {/* Add other routes here if necessary */}
    </Routes>
  );
}

export default App;
