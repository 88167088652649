import PropTypes from "prop-types";

const ColumnHome2 = ({ className = "", heading, text }) => {
  return (
    <div
      className={`flex-1 flex flex-row items-start justify-start gap-[1.5rem] min-w-[19rem] max-w-full text-left text-[2rem] text-text-primary font-text-small-normal mq450:flex-wrap ${className}`}
    >
      <img
        className="h-[3rem] w-[3rem] relative overflow-hidden shrink-0"
        alt=""
        src="/visionkomalicon.png"
      />
      <div className="flex-1 flex flex-col items-start justify-start gap-[1.5rem] min-w-[13.5rem] max-w-full">
        <div className="self-stretch flex flex-col items-start justify-start gap-[1.5rem]">
          <b className="self-stretch relative leading-[2.625rem] mq750:text-[1.625rem] mq750:leading-[2.063rem] mq450:text-[1.188rem] mq450:leading-[1.563rem]">
            {heading}
          </b>
          <div className="self-stretch relative text-[1rem] leading-[150%]">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

ColumnHome2.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string,
  text: PropTypes.string,
};

export default ColumnHome2;
